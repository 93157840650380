<template>
  <div id="settings-security">
     <!-- Back Button -->
    <v-btn id="back-button" icon @click="$router.push('/settings')" class="ml-minus-2">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>

    <!-- Title -->
    <h1 class="my-5 font-weight-medium">{{ $t('profile.cards.personal.security') }}</h1>

    <!-- List One -->
    <ListCard
      :data="listItems"
      class="mt-8"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'SettingsSecurity',
  components: {
    ListCard: lazyLoad('components/Settings/Card'),
  },
  computed: {
    ...mapGetters('user', [
      'user',
    ]),
    listItems() {
      return {
        items: [{
          icon: 'mdi-lock-outline',
          title: 'settings.security.list.email',
          path: '',
          disabled: true,
          data: this.user.email,
        },
        {
          icon: 'mdi-lock-outline',
          title: 'settings.security.list.password',
          path: '/settings/security/password',
        }],
      };
    },
  },
};
</script>
